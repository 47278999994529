import React, { useEffect } from 'react';

import AutoFixNormalSharpIcon from '@mui/icons-material/AutoFixNormalSharp';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import EditableDisplayDescription from 'client/app/apps/protocols/annotations/EditableDisplayDescription';
import EditableDisplayName from 'client/app/apps/protocols/annotations/EditableDisplayName';
import { useStepsContext } from 'client/app/apps/protocols/context/StepsProvider/StepsProvider';
import { useWorkflowContext } from 'client/app/apps/protocols/context/WorkflowProvider';
import { useProtocolContext } from 'client/app/apps/protocols/EditProtocol/context/ProtocolProvider';
import { InputStepList } from 'client/app/apps/protocols/InputStepList';
import {
  InputStepParameters,
  ParameterDescriberList,
  ParameterSkeletonList,
} from 'client/app/apps/protocols/InputStepParameters';
import { ProtocolStepParameterInfo } from 'client/app/apps/protocols/lib/types';
import {
  NoOutputStepPreview,
  OutputStepPreview,
  OutputStepPreviewPanel,
  OutputStepPreviewSkeleton,
  OutputStepPreviewTitle,
} from 'client/app/apps/protocols/OutputStepPreview';
import { ProtocolHeader } from 'client/app/apps/protocols/ProtocolHeader';
import {
  useStepTabViewContext,
  ViewTab,
} from 'client/app/apps/protocols/StepTabProvider';
import { isDefined } from 'common/lib/data';
import { ProtocolStepInput } from 'common/types/Protocol';
import {
  getElementId,
  getStageId,
  isElementPath,
  isStageDevicesPath,
  Schema,
} from 'common/types/schema';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import { useAdditionalPanelContext } from 'common/ui/providers/AdditionalPanelProvider';

type Props = {
  /**
   * navigateBack should enable navigation back to the previous stage of
   * protocol creation
   */
  navigateBack: () => void;
};

export const PersonaliseProtocol = ({ navigateBack }: Props) => {
  // loading of step state is what is important; not whether mutations of
  // protocol or workflow entities have been completed or not. Relying on the
  // latter would cause the skeleton to be shown every time we update the steps
  const {
    protocolSteps,
    workflowSchema,
    selectedStep,
    updateStep,
    updateStepInput,
    updateStepOutput,
    reorderStepInputs,
  } = useStepsContext();
  const { handleTabChange } = useStepTabViewContext();
  const { updateOutput } = useWorkflowContext();
  const { additionalPanelContents } = useAdditionalPanelContext();
  const loading = protocolSteps.length === 0;
  const activeOutputIndex = 0; // we only support 1 output atm
  const activeOutput = selectedStep?.outputs[activeOutputIndex];
  const inputParameters = useSchemaParameters(selectedStep?.inputs, workflowSchema);

  const { displayDescription, updateProtocol } = useProtocolContext();
  useEffect(() => {
    // any time step state is updated by any child component queue a protocol update
    updateProtocol({ protocol: { displayDescription, steps: protocolSteps } });
  }, [displayDescription, protocolSteps, updateProtocol]);

  // awkwardly element output preview depends on workflow builder context.
  // Therefore we can't simply pass the correct props to OutputStepPreview
  useEffect(() => {
    activeOutput && updateOutput(activeOutput.id);
  }, [activeOutput, updateOutput]);

  const stepName = selectedStep
    ? selectedStep.displayName
    : loading
    ? 'Loading step...'
    : 'Unnamed Step';

  const parameterList =
    loading || selectedStep === undefined ? (
      <ParameterSkeletonList />
    ) : (
      <ParameterDescriberList
        inputParameters={inputParameters}
        updateDescription={updateStepInput(selectedStep)}
        updateOrder={reorderStepInputs(selectedStep)}
      />
    );

  const outputTitle = activeOutput
    ? `Preview ${activeOutput.displayName}`
    : loading
    ? 'Loading preview...'
    : 'Preview';

  const handleAddOutput = () => {
    navigateBack();
    handleTabChange(ViewTab.OUTPUTS);
  };

  const outputPanelContent = loading ? (
    <OutputStepPreviewSkeleton />
  ) : selectedStep && selectedStep.outputs.length > 0 ? (
    <OutputStepPreview />
  ) : (
    <NoOutputStepPreview
      callToAction={
        <Button variant="secondary" onClick={handleAddOutput}>
          + ADD OUTPUT
        </Button>
      }
    />
  );

  return (
    <Layout>
      <ProtocolHeader
        icon={<AutoFixNormalSharpIcon color="primary" />}
        title="Personalise your protocol"
        description="Add new titles and descriptions to provide more context to your protocol."
      />
      <GridContainer>
        <InputStepList isDraggable alwaysExpandList />
        <SelectedStepPanel key={selectedStep?.id}>
          <InputStepParameters
            header={
              <EditableDisplayName
                name={stepName}
                onSave={name => selectedStep && updateStep(selectedStep)({ name })}
              />
            }
          >
            {/* don't place description in header. It could be very long in which case we want it to scroll with the inputs */}
            <Box marginBottom={3}>
              <EditableDisplayDescription
                description={selectedStep?.displayDescription as string}
                onSave={description =>
                  selectedStep && updateStep(selectedStep)({ description })
                }
              />
            </Box>
            {parameterList}
          </InputStepParameters>
          <OutputStepPreviewPanel noOutput={activeOutput === undefined}>
            <OutputStepPreviewTitle variant="h4">{outputTitle}</OutputStepPreviewTitle>
            <EditableDisplayDescription
              description={activeOutput?.displayDescription as string}
              onSave={description =>
                selectedStep &&
                updateStepOutput(selectedStep)(activeOutputIndex, { description })
              }
            />
            {outputPanelContent}
          </OutputStepPreviewPanel>
        </SelectedStepPanel>
        {additionalPanelContents && (
          <SelectedStepPanelOverlay>{additionalPanelContents}</SelectedStepPanelOverlay>
        )}
      </GridContainer>
    </Layout>
  );
};

const Layout = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

const GridContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplate: `
      "list inputs outputs" minmax(400px, 1fr)
      / auto 1fr 1fr`,
  flexGrow: 1,
  minHeight: 0,
  padding: theme.spacing(8),
  gap: theme.spacing(7),
  overflow: 'auto',
  backgroundColor: Colors.GREY_10,
}));

const SelectedStepPanelOverlay = styled('div')({
  gridColumnStart: 'inputs',
  gridColumnEnd: 'outputs',
  gridRow: 1,
  display: 'flex',
  zIndex: 2,
});

const SelectedStepPanel = styled('div')({
  gridColumnStart: 'inputs',
  gridColumnEnd: 'outputs',
  gridRow: 1,
  display: 'flex',
  overflowX: 'auto',
  zIndex: 1,
});

function useSchemaParameters(
  stepInputs: ProtocolStepInput[] = [],
  schema: Schema,
): ProtocolStepParameterInfo[] {
  const { getElementRelatedConfigDeviceIds, getStageRelatedConfigDeviceIds } =
    useWorkflowContext();
  return stepInputs
    .map(stepInput => {
      const schemaInput = schema.inputs?.find(i => i.id === stepInput.id);
      if (!schemaInput) {
        return undefined;
      }
      let relatedConfiguredDeviceIds: string[] = [];
      if (isElementPath(schemaInput.path)) {
        const elementInstanceId = getElementId(schemaInput.path)!;
        const { relatedConfiguredDeviceIds: configDeviceIds } =
          getElementRelatedConfigDeviceIds(elementInstanceId);
        relatedConfiguredDeviceIds = configDeviceIds;
      }
      if (isStageDevicesPath(schemaInput.path)) {
        const stageId = getStageId(schemaInput.path)!;
        relatedConfiguredDeviceIds = getStageRelatedConfigDeviceIds(stageId);
      }
      return {
        parameter: stepInput,
        value: schemaInput.default,
        elementInstanceId: getElementId(schemaInput.path),
        relatedConfiguredDeviceIds,
      };
    })
    .filter(isDefined);
}
