import React, { useEffect, useState } from 'react';

import FormatListIcon from '@mui/icons-material/FormatListBulleted';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { useStepsContext } from 'client/app/apps/protocols/context/StepsProvider/StepsProvider';
import { useWorkflowContext } from 'client/app/apps/protocols/context/WorkflowProvider';
import { useProtocolContext } from 'client/app/apps/protocols/EditProtocol/context/ProtocolProvider';
import {
  ProtocolOutputInstancePanel,
  ProtocolParameterInstancePanel,
  ProtocolStageParameterPanel,
} from 'client/app/apps/protocols/EditProtocol/DefineProtocol/InstancePanel';
import {
  InputStepContents,
  OutputStepContents,
  StepCard,
} from 'client/app/apps/protocols/EditProtocol/DefineProtocol/StepCard/';
import { useConfiguredElementParameterInfo } from 'client/app/apps/protocols/EditProtocol/DefineProtocol/useConfiguredElementParameterInfo';
import { ProtocolHeader } from 'client/app/apps/protocols/ProtocolHeader';
import { ResizableSidePanel } from 'client/app/apps/protocols/ResizableSidePanel';
import {
  MIN_TAB_WIDTH,
  TABS,
  useStepTabViewContext,
  ViewTab,
} from 'client/app/apps/protocols/StepTabProvider';
import WorkflowPreview from 'client/app/components/ExampleWorkflows/WorkflowPreview';
import { ElementInstance } from 'common/types/bundle';
import { isElementSource } from 'common/types/Protocol';
import Button from 'common/ui/components/Button';
import Tabs from 'common/ui/components/Tabs';
import { useAdditionalPanelContext } from 'common/ui/providers/AdditionalPanelProvider';

export default function DefineProtocol() {
  const [selectedObjectIds, setSelectedObjects] = useState<string[]>([]);

  const {
    activeTab,
    inputTabVisible,
    outputTabVisible,
    stageTabVisible,
    handleTabChange,
  } = useStepTabViewContext();

  const {
    displayDescription,
    updateProtocol,
    conflictDialog: protocolConflictDialog,
  } = useProtocolContext();
  const {
    workflowSchema,
    protocolSteps,
    stepsConfig,
    selectedStep,
    createStep,
    updateStep,
    toggleStepInput,
    toggleStepOutput,
    linkStepInputs,
    unlinkStepInput,
    deleteStep,
    deleteStepInput,
    deleteStepOutput,
    handleSelectStep,
  } = useStepsContext();
  const {
    id: workflowId,
    workflowStageParametersInfo,
    getElementInstance,
    handleUpdateSchema,
    conflictDialog: workflowConflictDialog,
  } = useWorkflowContext();
  const { additionalPanelContents } = useAdditionalPanelContext();
  const [elementInstance, setElementInstance] = useState<ElementInstance>();
  const elementInputs = useConfiguredElementParameterInfo(elementInstance);

  useEffect(() => {
    if (selectedObjectIds.length === 1) {
      const id = selectedObjectIds[0];
      const ei = getElementInstance(id);
      setElementInstance(ei);
    } else {
      setElementInstance(undefined);
    }
  }, [getElementInstance, selectedObjectIds]);

  useEffect(() => {
    // Workflow schema provides the functional aspect of a step, while protocol
    // steps provide the presentational aspect. So we need to update both. We
    // are safe to do this asynchronously since steps context is the single
    // source of truth and is independent of any entity mutations
    handleUpdateSchema(workflowSchema);
    updateProtocol({ protocol: { displayDescription, steps: protocolSteps } });
  }, [
    displayDescription,
    workflowSchema,
    protocolSteps,
    handleUpdateSchema,
    updateProtocol,
  ]);

  return (
    <Layout>
      <ProtocolHeader
        icon={<FormatListIcon color="primary" />}
        title="Define your protocol"
        description="Add steps to your protocol and select workflow parameters to include as protocol inputs and outputs for each step."
      />
      <Content>
        <ResizableSidePanel>
          <SidePanel>
            <ViewTabs
              tabsInfo={TABS}
              activeTab={activeTab}
              minimumTabWidth={MIN_TAB_WIDTH}
              onChangeTab={handleTabChange}
            />
            <StepCardWrapper>
              {protocolSteps.map(step => (
                <StepCard
                  key={step.id}
                  name={step.displayName}
                  active={step.id === selectedStep?.id}
                  onActivate={() => handleSelectStep(step.id)}
                  onChangeName={name => updateStep(step)({ name })}
                  onDelete={
                    protocolSteps.length > 1 ? () => deleteStep(step.id) : undefined
                  }
                >
                  {inputTabVisible && (
                    <InputStepContents
                      inputs={step.inputs.filter(isElementSource)}
                      emptyMessage="Select a parameter from the elements in the workflow."
                      onDelete={deleteStepInput(step)}
                      onLink={linkStepInputs(step)}
                      onUnlink={unlinkStepInput(step)}
                    />
                  )}
                  {outputTabVisible && (
                    <OutputStepContents
                      outputs={step.outputs}
                      onDelete={deleteStepOutput(step)}
                    />
                  )}
                  {stageTabVisible && (
                    <InputStepContents
                      inputs={step.inputs.filter(v => !isElementSource(v))}
                      emptyMessage="Select a parameter from the devices in the workflow."
                      onDelete={deleteStepInput(step)}
                      onLink={linkStepInputs(step)}
                      onUnlink={unlinkStepInput(step)}
                    />
                  )}
                </StepCard>
              ))}
            </StepCardWrapper>
            <Button variant="primary" color="primary" onClick={createStep}>
              + Add a step
            </Button>
          </SidePanel>
        </ResizableSidePanel>
        <PreviewWrapper>
          {workflowId && (
            <WorkflowPreview
              workflowId={workflowId}
              setSelectedObjects={setSelectedObjects}
              selectedObjectIds={selectedObjectIds}
            />
          )}
        </PreviewWrapper>
        {selectedStep && (
          <InstancePanelWrapper>
            {inputTabVisible && elementInstance && (
              <ProtocolParameterInstancePanel
                activeStepId={selectedStep.id}
                stepsConfig={stepsConfig}
                elementInstanceId={elementInstance.Id}
                elementInstanceName={elementInstance.name}
                inputs={elementInputs || []}
                onChange={toggleStepInput(selectedStep)}
                onClose={() => setSelectedObjects([])}
              />
            )}
            {outputTabVisible && elementInstance && (
              <ProtocolOutputInstancePanel
                activeStepId={selectedStep.id}
                stepsConfig={stepsConfig}
                elementInstanceId={elementInstance.Id}
                elementInstanceName={elementInstance.name}
                outputs={elementInstance.element.outputs}
                onChange={toggleStepOutput(selectedStep)}
                onClose={() => setSelectedObjects([])}
              />
            )}
            {stageTabVisible && (
              <ProtocolStageParameterPanel
                activeStepId={selectedStep.id}
                stepsConfig={stepsConfig}
                inputs={workflowStageParametersInfo}
                onChange={toggleStepInput(selectedStep)}
                onClose={() => handleTabChange(ViewTab.NONE)}
              />
            )}
          </InstancePanelWrapper>
        )}
        {additionalPanelContents && (
          <Dialog fullWidth maxWidth="lg" open>
            {additionalPanelContents}
          </Dialog>
        )}
        {workflowConflictDialog || protocolConflictDialog}
      </Content>
    </Layout>
  );
}
const Layout = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1',
}));

const Content = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
}));

const SidePanel = styled(Paper)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  padding: theme.spacing(4),
}));

const PreviewWrapper = styled('div')(() => ({
  flex: 1,
  overflow: 'hidden',
}));

const InstancePanelWrapper = styled('div')(() => ({
  position: 'absolute',
  right: 0,
  zIndex: 10,
  margin: '8px 8px 8px 8px',
  height: 'calc(100vh - 190px)',
  overflow: 'hidden',
}));

const ViewTabs = styled(Tabs)({
  '& button': {
    maxWidth: 'unset',
    flex: 1,
  },
}) as typeof Tabs;

const StepCardWrapper = styled(Stack)(({ theme }) => ({
  flexShrink: 1,
  minHeight: 0,
  overflowY: 'auto',
  gap: theme.spacing(5),
}));
