import React, { PropsWithChildren } from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Colors from 'common/ui/Colors';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';

type Props = PropsWithChildren & {
  icon: React.ReactNode;
  title: string;
  description: string;
};

export const ProtocolHeader = ({ icon, title, description, children }: Props) => {
  return (
    <Wrapper>
      {icon}
      <TypographyWrapper>
        <Header variant="h4">{title}</Header>
        <Typography variant="body2">{description}</Typography>
      </TypographyWrapper>
      {children && <RightContentWrapper>{children}</RightContentWrapper>}
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  gap: theme.spacing(4),
  borderBottom: `1px solid ${Colors.GREY_30}`,
  padding: theme.spacing(4, 6),
  minHeight: '70px',
}));

const TypographyWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const Header = styled(TypographyWithTooltip)(() => ({
  fontWeight: 600,
}));

const RightContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  marginLeft: 'auto',
  alignItems: 'center',
}));
